#sdgs-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#sdgs-container h4, #sdgs-container p {
  z-index: 2;
  max-width: 444px;
  position: relative;
}

#graph-container {
  justify-content: center;
  margin-top: 60px;
  display: flex;
}

.graph-item {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 30px;
  height: 266px;
  display: flex;
}

.bar-label {
  text-align: center;
  color: #bdbdbd;
  width: 26px;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
}

div.bar {
  background-color: #eee;
  width: 26px;
  height: 100px;
}

div.bar-container {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 26px;
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  display: flex;
}

.numbers-container p.x-axis-label {
  white-space: nowrap;
  color: #fff;
  text-align: center;
  width: 30px;
  height: 30px;
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 33px;
}

div.graph-item:first-child .x-axis-tooltip-container {
  visibility: visible;
  margin: 3px;
  position: relative;
  left: 35px;
}

div.graph-item .x-axis-tooltip-container {
  visibility: hidden;
  margin: 3px;
  position: relative;
  left: 35px;
}

div.x-axis-tooltip-shape {
  z-index: 0;
  border-radius: 24px;
  width: auto;
  padding: 8px 24px;
  transition: visibility .5s linear, opacity .5s linear;
  position: relative;
  box-shadow: 0 1px 5px #00000029;
}

div.x-axis-tooltip-shape:after {
  content: "";
  content: " ";
  z-index: 1;
  background-color: #fff;
  width: 20px;
  height: 16px;
  display: block;
  position: absolute;
  top: 30px;
  transform: rotate(-65deg)skew(-35deg);
  box-shadow: -3px 3px 3px -2px #00000029;
}

p.x-axis-tooltip {
  text-align: center;
  width: auto;
  font-size: 16px;
}

.speech-bubble-ds {
  color: #fff;
  text-align: center;
  background: #fff;
  border-radius: 24px;
  max-width: 400px;
  margin: 0 auto 40px;
  padding: 8px 24px;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  box-shadow: 0 1px 5px #00000029;
}

.speech-bubble-ds p {
  margin-bottom: 10px;
}

.speech-bubble-ds p:last-of-type {
  margin-bottom: 0;
}

.speech-bubble-ds-arrow {
  border-top: 20px solid #00000029;
  border-left: 21px solid #0000;
  position: absolute;
  bottom: -25px;
  right: 15px;
}

.speech-bubble-ds-arrow:before {
  content: "";
  border-top: 23px solid #a7a7a7;
  border-left: 23px solid #0000;
  position: absolute;
  bottom: 2px;
  right: 5px;
}

.speech-bubble-ds-arrow:after {
  content: "";
  border-top: 21px solid #fff;
  border-left: 21px solid #0000;
  position: absolute;
  bottom: 4px;
  right: 6px;
}

div.graph-item:hover > div.x-axis-tooltip-container {
  visibility: visible;
  opacity: 1;
  display: block;
}

.graph-item:not(:first-child) {
  margin-left: 12px;
}

@keyframes bar-grow {
  from {
    height: 0%;
  }

  to {
    height: 100%;
  }
}

@media only screen and (width <= 960px) {
  .graph-item {
    width: 15px;
  }

  .bar-label {
    width: 13px;
    font-size: 8px;
  }

  div.bar, div.bar-container {
    width: 13px;
  }

  .numbers-container p.x-axis-label {
    width: 15px;
    height: 15px;
    margin-top: 6px;
    font-size: 8px;
    line-height: 16px;
  }

  .graph-item:not(:first-child) {
    margin-left: 6px;
  }
}
/*# sourceMappingURL=foundgoodorg.32867335.css.map */
