
/* positioning the graph */
#sdgs-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

#sdgs-container h4, #sdgs-container p {
	max-width: 444px;
	z-index: 2;
    position: relative;	
}

#graph-container {
	display: flex;
	justify-content: center;
	margin-top: 60px;
}

/* positioning the graph bar items */
.graph-item {
	display: flex;
	flex-direction: column;
	width: 30px;
	align-items: center;
	justify-content: flex-end;
	height: 266px;
}


/* style the graph bar items */
.bar-label {
	text-align: center;
	color: #BDBDBD;
	font-size: 16px;
	width: 26px;
	line-height: 24px;
	height: 24px;
}

div.bar {
	width: 26px;
	background-color: #EEEEEE;
	height: 100px;
}

div.bar-container {
	min-height: 200px;
	max-height: 200px;
	height: 200px;
	display: flex;
	flex-direction: column;
	width: 26px;
	align-items: center;
	justify-content: flex-end;
}

.numbers-container p.x-axis-label {
	margin-top: 12px;
	width: 30px;
	height: 30px; /* font doesn't sit centrally on vertical axis so this is a hack to make it look centered */
	line-height: 33px; /* font doesn't sit centrally on vertical axis so this is a hack to make it look centered */
	white-space: nowrap;

	font-weight: 500;
	font-size: 16px;
	color: white;
	text-align: center;
}

div.graph-item:first-child .x-axis-tooltip-container {
	margin: 3px;
	position: relative;
	left: 35px;
	visibility: visible;
}

div.graph-item .x-axis-tooltip-container {
	margin: 3px;
	position: relative;
	left: 35px;
	visibility: hidden;
}

div.x-axis-tooltip-shape {
	position: relative;
	/*opacity: 0;*/
	transition: visibility 0.5s linear,opacity 0.5s linear;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.16);
	border-radius: 24px;
	width: auto;
	padding: 8px 24px;
	z-index: 0;
}

div.x-axis-tooltip-shape:after {
	content: '';
    background-color: white;
    content: "\00a0";
    display: block;
    height: 16px;
    position: absolute;
    top: 30px;
    transform:             rotate( -65deg ) skew( -35deg );
        -moz-transform:    rotate( -65deg ) skew( -35deg );
        -ms-transform:     rotate( -65deg ) skew( -35deg );
        -o-transform:      rotate( -65deg ) skew( -35deg );
        -webkit-transform: rotate( -65deg ) skew( -35deg );
    box-shadow: -3px 3px 3px -2px rgba(0, 0, 0, 0.16);
    width:  20px;
    z-index: 1;
}

p.x-axis-tooltip {
	font-size: 16px;
	/* identical to box height, or 150% */
	text-align: center;
	width: auto;
}

.speech-bubble-ds {
    background: #ffffff;
    -webkit-border-radius: 4px;
            border-radius: 4px;
    -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.16);
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.16);

    margin: 0 auto 40px;
    max-width: 400px;
	border-radius: 24px;
    padding: 8px 24px;
    position: relative;

    font-weight: 500;
	font-size: 16px;
	color: white;
	text-align: center;
}

.speech-bubble-ds p {
    margin-bottom: 10px;
}
.speech-bubble-ds p:last-of-type {
    margin-bottom: 0;
}

.speech-bubble-ds-arrow {
    border-left: 21px solid transparent;
    border-top: 20px solid rgba(0, 0, 0, 0.16);
    bottom: -25px;
    position: absolute;
    right: 15px;
}
.speech-bubble-ds-arrow::before {
    border-left: 23px solid transparent;
    border-top: 23px solid #a7a7a7;
    bottom: 2px;
    content: "";
    position: absolute;
    right: 5px;
}
.speech-bubble-ds-arrow::after {
    border-left: 21px solid transparent;
    border-top: 21px solid #ffffff;
    bottom: 4px;
    content: "";
    position: absolute;
    right: 6px;
}


div.graph-item:hover > div.x-axis-tooltip-container {
	display: block;
	visibility: visible;
	opacity: 1;

}

/* all but the first graph bar item */
.graph-item:not(:first-child) {
	margin-left: 12px;
}

/* animate graph */

@keyframes bar-grow {
	from {
		height:0%;
	}

	to {
		height: 100%;
	}
}

/** Rules for tablet **/
@media only screen and (max-width: 960px) {

	.graph-item {
		width: 15px;
	}

	.bar-label {
		width: 13px;
		font-size: 8px
	}

	div.bar {
		width: 13px;
	}

	div.bar-container {
		width: 13px;
	}

	.numbers-container p.x-axis-label {
		width: 15px;
		height: 15px;
		font-size: 8px;
	    margin-top: 6px;
	    line-height: 16px;		
	}

	.graph-item:not(:first-child) {
		margin-left: 6px;
	}
}
